import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { Home } from './components/Home';
import AuthService from './AuthService'; //Auth0

export default class App extends Component {
    static displayName = App.name;

    constructor() {
        super();
        this.authService = new AuthService(); //Auth0
    }

    renderHome() {
        let resultComponent = <Home auth={this.authService} />;

        if (!this.authService.isAuthenticated()) {
            this.authService.login();
            resultComponent = <div><p>Redirecting to the authentication service...</p></div>
        }

        return resultComponent;
    }

    startSession(history) {
        this.authService.handleAuthentication(history);
        return <div><p>Starting session...</p></div>;
    }

    render() {
    return (
      <Switch>
            <Route exact path="/" render={() => this.renderHome()} />
            <Route path="/startSession" render={({ history }) => this.startSession(history)} />
      </Switch>
    );
  }
}
