import React, { Component } from 'react';
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

 const style = {
    height: "60vh" // we can control scene size by setting container dimensions
};

export class ThreeWindow_Plane extends Component {
    static displayName = ThreeWindow_Plane.name;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.sceneSetup();
        this.addCustomSceneObjects();
        this.startAnimationLoop();
        window.addEventListener("resize", this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
        window.cancelAnimationFrame(this.requestID);
        this.controls.dispose();
    }

    // Standard scene setup in Three.js. Check "Creating a scene" manual for more information
    // https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
    sceneSetup = () => {
        // get container dimensions and use them for scene sizing
        const width = this.el.clientWidth;
        const height = this.el.clientHeight;

        this.scene = new THREE.Scene();

        this.camera = new THREE.PerspectiveCamera(
            27, // fov = field of view
            width / height, // aspect ratio
            5, // near plane
            35000 // far plane
        );
        this.camera.position.x = 27500;

        // OrbitControls allow a camera to orbit around the object
        // https://threejs.org/docs/#examples/controls/OrbitControls
        this.controls = new OrbitControls(this.camera, this.el);
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(width, height);
        this.el.appendChild(this.renderer.domElement); // mount using React ref
    };

    addCustomSceneObjects = () => {
        var positions = [];
        var colors = [];
        var color = new THREE.Color();

        ////Init Data - Base Station
        //for (var i = 0; i < pointcloud.points.length; i++) {

        //    // positions
        //    var x = pointcloud.points[i].x;
        //    var y = pointcloud.points[i].y;
        //    var z = pointcloud.points[i].z;

        //    if (x !== 0 && y !== 0 && z !== 0) {
        //        //push non-zero values
        //        positions.push(x, y, z);
        //        // colors
        //        var vx = pointcloud.points[i].r;
        //        var vy = 255;
        //        var vz = 0;
        //        color.setRGB(vx, vy, vz);
        //        colors.push(color.r, color.g, color.b);
        //    }
        //}

        var geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
        geometry.computeBoundingSphere();

        var material = new THREE.PointsMaterial({ size: 10, vertexColors: THREE.VertexColors });
        var points = new THREE.Points(geometry, material);
        this.scene.add(points);
    };

    startAnimationLoop = () => {
        //this.cube.rotation.x += 0.01;
        //this.cube.rotation.y += 0.01;

        this.renderer.render(this.scene, this.camera);

        // The window.requestAnimationFrame() method tells the browser that you wish to perform
        // an animation and requests that the browser call a specified function
        // to update an animation before the next repaint
        this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
    };

    handleWindowResize = () => {
        const width = this.el.clientWidth;
        const height = this.el.clientHeight;

        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
    };

    render() {
        return <div style={style} ref={ref => (this.el = ref)} />;
    }

}
